import React from "react";
import "./styles.css";
const Input = (props) => {
    let { id, label, type = "text", ...config } = props;
    return (
        <div id="input">
            <label htmlFor={id}>{label || id}</label>
            <br />
            <input type={type} name={id} id={id} {...config} />
        </div>
    );
};

export default Input;
