import React from "react";
import { Link } from "react-router-dom";
import Create from "./create";
import "./styles.css";
import View from "./view";

const newVendas = () => {
    return (
        <div id="newVendas">
            <nav id="nav">
                <Link to="inicio">
                    <button className="rev"> inicio</button>
                </Link>
            </nav>
            <Create />
            <main className="main">
                <View />
            </main>
            <footer className="footer">
                <h1>valores</h1>
            </footer>
        </div>
    );
};

export default newVendas;
