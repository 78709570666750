import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";
const Header = () => (
    <header className="header" id="main-header">
        <Link to="/">
            <h1>Estoquer</h1>
        </Link>
        <div className="menu">
            <Link to={"/"}>
                <button>Inicio</button>
            </Link>
            <Link to={"/clientes"}>
                <button>Clientes</button>
            </Link>
            {/* <Link to={"/nvendas"}>
                <button>NVendas</button>
            </Link> */}
            <Link to={"/vendas"}>
                <button>Vendas</button>
            </Link>
            <Link to={"/relatorios"}>
                <button>Relatórios</button>
            </Link>
            <Link to={"/produtos"}>
                <button>Produtos</button>
            </Link>
            <Link to={"/perfil"}>
                <button>{localStorage.getItem("nome").split(" ")[0]}</button>
            </Link>
        </div>
    </header>
);

export default Header;
