import React from "react";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import ClienteCreate from "./create";
import ClienteFind from "./find";
import Filtros from "./filtros";
import Floating from "../../components/Floating";
import "./styles.css";
import Mask from "../../services/Mask";
import { GlobalContext } from "../../services/globalContext";

const reduceValor = (prev, current) => {
    return prev + current.valor * current.quantidade;
};

export default () => {
    let { clientes, loading, update: updateClientes } = React.useContext(
        GlobalContext,
    ).clientes;
    let { update: updateProdutos } = React.useContext(GlobalContext).produtos;
    let [modalCreate, setModalCreate] = React.useState(false);
    let [modalFind, setModalFind] = React.useState(false);
    function update() {
        updateProdutos();
        updateClientes();
    }
    if (loading) {
        return (
            <>
                <Header />
                {modalFind && (
                    <Modal close={() => setModalFind(false)}>
                        <ClienteFind
                            cliente={
                                clientes.filter(
                                    (cliente) => cliente.id === modalFind,
                                )[0]
                            }
                            atualizer={update}
                            close={() => setModalFind(false)}
                        />
                    </Modal>
                )}
                <Loading />
                <Footer />
            </>
        );
    } else if (!clientes.length) {
        return (
            <>
                <Header />
                <div id="Clientes">
                    <h1 className="title">Você não possue clientes cadastrados</h1>
                    {modalCreate && (
                        <Modal close={() => setModalCreate(false)}>
                            <ClienteCreate
                                close={() => setModalCreate(false)}
                                atualizar={update}
                            />
                        </Modal>
                    )}
                    <div className="title">
                        <button onClick={() => setModalCreate(true)}>
                            Cadastrar clientes
                        </button>
                    </div>
                    <Footer />
                </div>
            </>
        );
    }

    return (
        <>
            <Header />
            <div id="Clientes">
                <Filtros itens={clientes} create={() => setModalCreate(true)} />
                <Floating>
                    <button className="rev" onClick={() => setModalCreate(true)}>
                        Cadastrar clientes
                    </button>
                </Floating>
                {modalCreate && (
                    <Modal close={() => setModalCreate(false)}>
                        <ClienteCreate
                            close={() => setModalCreate(false)}
                            atualizar={update}
                        />
                    </Modal>
                )}

                {modalFind && (
                    <Modal close={() => setModalFind(false)}>
                        <ClienteFind
                            cliente={
                                clientes.filter(
                                    (cliente) => cliente.id === modalFind,
                                )[0]
                            }
                            atualizer={update}
                            close={() => setModalFind(false)}
                        />
                    </Modal>
                )}
                <div className="itens">
                    {clientes.map((item, i) => {
                        return (
                            <article key={item.id} id={item.nome}>
                                <h1>{item.nome}</h1>
                                <div>
                                    <p>Quantidade: {item.debts.length}</p>
                                    <p>
                                        Valor:{" "}
                                        {Mask.moeda(
                                            item.debts.reduce(reduceValor, 0),
                                        )}
                                    </p>
                                </div>
                                <button onClick={() => setModalFind(item.id)}>
                                    acessar
                                </button>
                            </article>
                        );
                    })}
                </div>
            </div>
            <Footer />
        </>
    );
};
