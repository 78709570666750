import React from "react";
import Mask from "../../../services/Mask";

export const Produtos = ({ produtos = [] }) => {
    let [compraValue, setCompraValue] = React.useState(0);
    let [vendaValue, setVendaValue] = React.useState(0);
    let [unidades, setUnidades] = React.useState(0);
    let [length, setLength] = React.useState(0);

    function calcs() {
        setVendaValue(
            produtos.reduce((prev, current) => {
                return prev + current.venda * current.quantidade;
            }, 0),
        );

        setCompraValue(
            produtos.reduce((prev, current) => {
                return prev + current.compra * current.quantidade;
            }, 0),
        );

        setUnidades(
            produtos.reduce((prev, current) => {
                return prev + current.quantidade;
            }, 0),
        );

        setLength(produtos.length);
    }
    React.useEffect(calcs, []);

    return (
        <section className="produtos">
            <h1 className="title">Produtos</h1>
            <div className="grid">
                <article>
                    <p> Produtos: {length} </p>
                </article>
                <article>
                    <p> Unidades: {unidades}</p>
                </article>
                <article>
                    <p> Compra: {Mask.moeda(compraValue)}</p>
                    <p> Venda: {Mask.moeda(vendaValue)}</p>
                    <p> Lucro: {Mask.moeda(vendaValue - compraValue)}</p>
                </article>
            </div>
        </section>
    );
};
