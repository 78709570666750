import React from "react";
import Loading from "../../../components/Loading";
import api from "../../../services/api";
import Venda from "./venda";

const View = () => {
    let [vendas, setVendas] = React.useState([]);
    let [loading, setLoading] = React.useState(true);
    let [error, setError] = React.useState(false);

    async function request() {
        setError(false);
        let { data, res } = await api.post("vendas", {
            token: localStorage.getItem("token"),
        });

        setLoading(false);

        if (data.error) {
            return setError(data.error);
        } else if (res.status !== 200) {
            return setError("Ouve algum erro");
        }

        setVendas(data);
    }

    React.useEffect(() => {
        request();
    });
    
    if (error) {
        return <h1 className="error">{error}</h1>;
    } else if (loading) {
        return <Loading />;
    }
    return (
        <>
            {vendas.map((venda, i) => {
                return <Venda venda={venda} />;
            })}
        </>
    );
};

export default View;
