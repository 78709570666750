import React from "react";
import Input from "../../../../components/Input";
import Loading from "../../../../components/Loading";
import api from "../../../../services/api";
import Mask from "../../../../services/Mask";
import Notify from "../../../../services/Notify";
import "./styles.css";

const Update = ({
    infos = {},
    setInfos = () => {},
    close = () => {},
    atualizer = () => {},
}) => {
    let [confirm, setConfirm] = React.useState(false);
    let [error, setError] = React.useState(false);
    let [loading, setLoading] = React.useState(false);
    let [cliente, setCliente] = React.useState(infos);

    async function handleAtualizar(e) {
        e.preventDefault();
        setError(false);
        if (loading) return;
        if (!confirm) {
            return setConfirm(true);
        }

        setLoading(true);

        let { data, res } = await api.post("/clientes/update", {
            cliente_id: cliente.id,
            ...cliente,
            token: localStorage.getItem("token"),
        });

        setLoading(false);

        if (data.error) {
            setError(data.error);
            return;
        } else if (res.status !== 200) {
            setError("Ocoreu algum erro");
            return;
        }
        Notify.new("Cliente atualizado");
        setInfos(cliente);
        setConfirm(false);
        await atualizer();
        close();
    }

    function setValue(e) {
        let { value, id } = e.target;
        setCliente({ ...cliente, [id]: value });
        setConfirm(false);
    }

    function setAdress(e) {
        let { value, id } = e.target;
        let infs = cliente;
        infs.adress[id] = value;
        setCliente({ ...infs });
        setConfirm(false);
    }
    function setBlock() {
        setConfirm(false);
        setCliente({ ...cliente, block: !cliente.block });
    }

    return (
        <div className="modalClienteAtualizar">
            <form onSubmit={handleAtualizar}>
                <h1>atualizar</h1>

                {loading && <Loading />}
                {error && <h1 className="error"> {error}</h1>}
                <div className="infos">
                    <article>
                        <Input
                            label="Nome:"
                            id="nome"
                            value={cliente.nome}
                            onChange={setValue}
                            required
                        />
                    </article>
                    <article>
                        <Input
                            id="email"
                            label="Email:"
                            value={cliente.email}
                            onChange={setValue}
                        />
                    </article>
                    <article>
                        <Input
                            id="cpf"
                            label="CPF:"
                            value={Mask.cpf(cliente.cpf)}
                            onChange={setValue}
                            required
                        />
                    </article>
                    <article>
                        <Input
                            id="telefone"
                            label="Telefone:"
                            type="number"
                            value={cliente.telefone}
                            onChange={setValue}
                            required
                        />
                    </article>
                    <article>
                        <Input
                            type="number"
                            label="Limite de vendas"
                            id="max_value"
                            value={cliente.max_value}
                            onChange={setValue}
                        />
                    </article>
                    <article>
                        <label htmlFor="block">
                            {cliente.block ? "Bloqueado" : "Não bloqueado"}
                        </label>
                        <button type="button" onClick={setBlock}>
                            {cliente.block ? "Desbloquear" : "Bloquear"}
                        </button>
                    </article>
                </div>
                <h1>Endereço</h1>
                <div className="infos">
                    <article>
                        <Input
                            id="rua"
                            label="Rua:"
                            value={cliente.adress.rua}
                            onChange={setAdress}
                            required
                        />
                    </article>
                    <article>
                        <Input
                            type="number"
                            id="numero"
                            label="Número:"
                            value={cliente.adress.numero}
                            onChange={setAdress}
                            required
                        />
                    </article>
                    <article>
                        <Input
                            id="bairro"
                            label="Bairro:"
                            value={cliente.adress.bairro}
                            onChange={setAdress}
                            required
                        />
                    </article>
                    <article>
                        <Input
                            id="complemento"
                            label="Complemento:"
                            value={cliente.adress.complemento}
                            onChange={setAdress}
                        />
                    </article>
                </div>
                <button type="submit">{confirm ? "Confirmar" : "Atualizar"}</button>
                <button type="button" onClick={() => close()}>
                    Canselar
                </button>
            </form>
        </div>
    );
};

export default Update;
