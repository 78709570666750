import React from "react";
import Input from "../../../components/Input";
import Loading from "../../../components/Loading";
import api from "../../../services/api";
import Mask from "../../../services/Mask";
import Notify from "../../../services/Notify";
import "./styles.css";

let formCampos = [
    {
        label: "Nome",
        type: "text",
        id: "nome",
        config: {
            required: true,
            autoFocus: true,
            minLength: 3,
        },
    },
    {
        label: "CPF",
        type: "text",
        id: "cpf",
        config: {
            required: true,
            minLength: 14,
            maxLength: 14,
        },
    },
    {
        label: "Telefone",
        type: "text",
        id: "telefone",
        config: {
            required: true,
            minLength: 17,
            maxLength: 17,
        },
    },
    {
        label: "Limite de vendas (opcional)",
        type: "number",
        id: "max_value",
        config: {
            required: false,
            min: 0,
        },
        default: 0,
    },
    {
        label: "Email (opcional)",
        type: "email",
        id: "email",
        config: {
            required: false,
        },
    },
];
let campoEndereco = [
    {
        label: "Rua",
        type: "text",
        id: "rua",
        config: {
            required: true,
        },
    },
    {
        label: "Número",
        type: "number",
        id: "numero",
        config: {
            required: true,
        },
    },
    {
        label: "Bairro",
        type: "text",
        id: "bairro",
        config: {
            required: true,
        },
    },
    {
        label: "Complemento",
        type: "text",
        id: "complemento",
        config: {
            required: false,
        },
    },
];

let campos = formCampos.reduce((acc, campo) => {
    return {
        ...acc,
        [campo.id]: campo.default !== undefined ? campo.default : "",
    };
}, {});

let formEndereco = campoEndereco.reduce((acc, campo) => {
    return { ...acc, [campo.id]: "" };
}, {});

const ClienteCreate = ({ close, atualizar }) => {
    const [cliente, setCliente] = React.useState(campos);
    const [adress, setAdress] = React.useState(formEndereco);
    const [erro, setErro] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    async function sendInfos(event) {
        event.preventDefault();
        setErro(false);
        setLoading(true);
        let { data, res } = await api.post("/clientes/create", {
            ...cliente,
            adress,
            token: localStorage.getItem("token"),
        });
        setLoading(false);
        if (data.error) {
            setErro(data.error);
            return;
        } else if (res.status !== 201) {
            setErro("Ocoreu algum erro");
            return;
        }
        Notify.new("Cliente salvo");
        atualizar();
        setCliente(campos);
        setAdress(formEndereco);
    }

    function setValue({ target }) {
        let value = target.value,
            id = target.id;

        if (id === "telefone") {
            value = Mask.telefone(value);
        } else if (id === "cpf") {
            value = Mask.cpf(value);
        }
        setCliente({ ...cliente, [id]: value });
    }

    function setAdresses({ target }) {
        setAdress({ ...adress, [target.id]: target.value });
    }

    return (
        <div className="ClienteCreate">
            <h1 className="title">Registrar cliente</h1>
            {erro !== false ? <h1>{erro}</h1> : ""}
            {loading ? <Loading /> : ""}
            <form onSubmit={sendInfos}>
                <div className="grid">
                    {formCampos.map((campo, i) => (
                        <article key={campo.id}>
                            <Input
                                id={campo.id}
                                label={campo.label}
                                type={campo.type}
                                {...campo.config}
                                value={cliente[campo.id]}
                                onChange={setValue}
                            />
                        </article>
                    ))}
                </div>
                <h2 className="title">Endereço</h2>
                <br />
                <div className="grid">
                    {campoEndereco.map((campo, i) => (
                        <article key={campo.id}>
                            <Input
                                id={campo.id}
                                label={campo.label}
                                type={campo.type}
                                {...campo.config}
                                value={adress[campo.id]}
                                onChange={setAdresses}
                            />
                        </article>
                    ))}
                    <article id="buttons">
                        <button className="rev" type="submit">
                            Registrar
                        </button>
                    </article>
                </div>
            </form>
        </div>
    );
};
export default ClienteCreate;
