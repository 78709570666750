import React from "react";
import api from "../../../services/api";
import notify from "../../../services/Notify";
import { Update } from "../update";
import Loading from "../../../components/Loading";
import "./styles.css";
import Mask from "../../../services/Mask";
const ModalProdutos = ({ produto, close, atualizer }) => {
    const { id } = produto;
    let [product, setProduct] = React.useState(produto);
    let [dell, setDelete] = React.useState(false);
    let [error, setError] = React.useState(false);
    let [loading, setLoading] = React.useState(false);
    let [atualiza, setAtualiza] = React.useState(0);

    async function deletar() {
        if (!dell) return setDelete(true);
        setError(false);
        setLoading(true);
        let { data, res } = await api.post("/produtos/delete", {
            id,
            token: localStorage.getItem("token"),
        });
        setLoading(false);
        if (data.error) {
            return setError(data.error);
        } else if (res.status !== 200) {
            return setError("status " + res.status);
        }
        await atualizer();
        notify.new("Produto deletado");
        close();
    }

    if (atualiza) {
        return (
            <Update
                atualizer={atualizer}
                infos={product}
                setInfos={setProduct}
                close={() => {
                    setAtualiza(false);
                }}
            />
        );
    }

    return (
        <div className="modalProduto">
            <h1 className="title">{product.nome}</h1>
            {error && <h1 className="error">{error}</h1>}
            {loading && <Loading />}
            <div className="infos">
                <article>
                    <h2>Valor de compra: {Mask.moeda(product.compra)}</h2>
                </article>
                <article>
                    <h2>Valor de venda: {Mask.moeda(product.venda)}</h2>
                </article>
                {product.codigo && (
                    <article>
                        <h2>Código: {product.codigo}</h2>
                    </article>
                )}
                {product.description && (
                    <article>
                        <h2>Descrição: {product.description}</h2>
                    </article>
                )}
                <article>
                    <h2>Quantidade: {product.quantidade}</h2>
                </article>
                <article>
                    <h2>
                        Total de venda:{" "}
                        {Mask.moeda(product.venda * product.quantidade)}
                    </h2>
                </article>
            </div>

            <button className="rev" onClick={() => setAtualiza(1)}>
                atualizer
            </button>
            <button onClick={deletar} className="rev">
                {!dell ? "Deletar" : "Confirmar!"}
            </button>

            {produto.vendas && (
                <div className="vendas">
                    {produto.vendas.map((venda, index) => {
                        return (
                            <article key={index} className="venda">
                                <p className="quantidade">
                                    Quantidade: <br />
                                    {venda.quantidade}
                                </p>
                                <p className="data">
                                    Data: <br />
                                    {Mask.dataPop(venda.createdAt)}
                                </p>
                                <p className="valor">
                                    Valor: <br />
                                    {Mask.moeda(venda.valor)}
                                </p>
                                <p className="total">
                                    Total: <br />
                                    {Mask.moeda(venda.valor * venda.quantidade)}
                                </p>
                            </article>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ModalProdutos;
