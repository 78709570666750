import React from "react";
import Mask from "../../../services/Mask";

const reduceValor = (prev, current) => {
    return prev + current.valor * current.quantidade;
};
const reduceUnidades = (prev, current) => {
    return prev + current.quantidade;
};

const reduceLucro = (prev, current) => {
    if (!current.produto) return prev + current.valor * current.quantidade;
    let lucro = current.valor - current.produto.compra;
    return prev + lucro * current.quantidade;
};

const defalt = {
    valor: 0,
    unidades: 0,
    quantidade: 0,
    lucro: 0,
};
export const Vendas = ({ vendas = [] }) => {
    let [todos, setTodos] = React.useState({ ...defalt });
    let [hoje, setHoje] = React.useState({ ...defalt });
    let [mes, setMes] = React.useState({ ...defalt });
    function monthOrder() {
        let month = Mask.dataPop().split("/");
        month.shift();
        month = month.join("/");

        return vendas.filter((venda) => {
            let date = Mask.dataPop(venda.createdAt).split("/");
            date.shift();
            date = date.join("/");
            if (date === month) {
                return true;
            }
            return false;
        });
    }

    function dayOrder() {
        let day = Mask.dataPop();

        return vendas.filter((venda) => {
            let date = Mask.dataPop(venda.createdAt);
            if (date === day) {
                return true;
            }
            return false;
        });
    }

    function init() {
        let obj = {};

        obj.valor = vendas.reduce(reduceValor, 0);
        obj.unidades = vendas.reduce(reduceUnidades, 0);
        obj.quantidade = vendas.length;
        obj.lucro = vendas.reduce(reduceLucro, 0);

        setTodos(obj);
        hendleDia();
        handleMes();
    }

    function hendleDia() {
        let vendas = dayOrder();
        let obj = {};
        if (!vendas || !vendas.length) return;

        obj.valor = vendas.reduce(reduceValor, 0);
        obj.unidades = vendas.reduce(reduceUnidades, 0);
        obj.quantidade = vendas.length;
        obj.lucro = vendas.reduce(reduceLucro, 0);

        setHoje(obj);
        handleMes();
    }
    function handleMes() {
        let vendas = monthOrder();
        let obj = {};

        obj.valor = vendas.reduce(reduceValor, 0);
        obj.unidades = vendas.reduce(reduceUnidades, 0);
        obj.quantidade = vendas.length;
        obj.lucro = vendas.reduce(reduceLucro, 0);

        setMes(obj);
    }
    React.useEffect(init, [vendas]);
    return (
        <section className="vendas">
            <h1 className="title">Vendas</h1>
            <div className="grid">
                <article>
                    <h1 className="title">Hoje</h1>
                    <p>Quantidade: {hoje.quantidade}</p>
                    <p>Unidades: {hoje.unidades}</p>
                    <p>Valor: {Mask.moeda(hoje.valor)}</p>
                    {hoje.lucro !== 0 && <p>Lucro: {Mask.moeda(hoje.lucro)}</p>}
                </article>
                <article>
                    <h1 className="title">Mês</h1>
                    <p>Quantidade: {mes.quantidade}</p>
                    <p>Unidades: {mes.unidades}</p>
                    <p>Valor: {Mask.moeda(mes.valor)}</p>
                    {mes.lucro !== 0 && <p>Lucro: {Mask.moeda(mes.lucro)}</p>}
                </article>
                <article>
                    <h1 className="title">Total</h1>
                    <p>Quantidade: {todos.quantidade}</p>
                    <p>Unidades: {todos.unidades}</p>
                    <p>Valor: {Mask.moeda(todos.valor)}</p>
                    {todos.lucro !== 0 && <p>Lucro: {Mask.moeda(todos.lucro)}</p>}
                </article>
            </div>
        </section>
    );
};
