let href = document.location.href
href = href.split(':')
href.pop()
href.push('1313')
href = href.join(':')
    // let baseURL = 'http://142.93.119.35:1313/api'
    // let baseURL = "http://localhost:1313/api";
let baseURL = "http://142.93.119.35:1313/api";
// let baseURL = `${href}/api`

let myHeaders = new Headers();

myHeaders.set("Content-Type", "application/json");
// myHeaders.set('Origin', 'http://10.0.0.9:1313')

class api {
    constructor(uri, headers) {
        this.uri = uri;
        this.headers = headers;
    }

    async get(url = "/") {
        return fetch(this.uri + url, {
            method: "GET",
            mode: "cors",
            headers: this.headers,
            cache: "default",
        });
    }

    async post(url = "/", body) {
        if (!url.startsWith("/", 0)) {
            url = "/" + url;
        }
        console.log(this.uri + url);
        return fetch(this.uri + url, {
                method: "POST",
                mode: "cors",
                headers: this.headers,
                cache: "default",
                body: JSON.stringify(body),
            })
            .then(async(res) => {
                let json = await res.json();

                if (json.error && json.error === "Token invalido") {
                    // alert(json.error)
                    document.location = "/login";
                }
                return {
                    data: json,
                    res,
                };
            })
            .catch((err) => {
                console.log(err.message);
                return {
                    data: {
                        error: "Não foi posivel entrar em contato com o servidor",
                    },
                };
            });
    }
}

export default new api(baseURL, myHeaders);