import React from 'react'
import Header from '../../components/Header'
import Loading from '../../components/Loading'
import api from '../../services/api'
import CardRalatorios from './Card'
import SectionRealatorios from './Section/Section'
import './styles.css'

export default () => {
    const [loading, SetLoading] = React.useState(true)
    const [error, setError] = React.useState(true)
    const [relatorios, setRelatorios] = React.useState({})
    const [Keys, setKeys] = React.useState([])

    async function request() {

        let { data, res } = await api.post("/vendas/balance", {
            token: localStorage.getItem("token"),
        });

        if (data.error) return setError(data.error);
        if (res.status !== 200) return setError('Entre em contato com o suporte');

        console.log(data)
        setRelatorios(data)
        setKeys(Object.keys(data))
        SetLoading(false)
    }

    React.useEffect(() => {
        request()
    }, [])
    if (loading) {
        return (
            <>
                <Header />
                <Loading />
            </>
        );
    }
    console.log(Keys);
    return (
        <>
            <Header />
            <div className='RelatoriosPage'>
                {Keys.map(key => {
                    return (
                        <SectionRealatorios title={key}>
                            {Object.keys(relatorios[key]).map(mes=>{
                                let value=relatorios[key][mes];
                                return <CardRalatorios title={mes} value={value.toFixed(2)} />
                            })}
                        </SectionRealatorios>
                    )
                })}
            </div>
        </>
    )
}
