import api from "./api";

export default async() => {
    if (!localStorage.token || !localStorage.nome) {
        return false;
    }

    let { data } = await api.post("/users/token/validate", {
        token: localStorage.getItem("token"),
    });

    if (data === true) {
        return true;
    } else {
        localStorage.clear();
        return false;
    }
};