import React from "react";
import Mask from "../../../services/Mask";

const Produto = ({ item, setModelViwe }) => {
    return (
        <article
            key={item.id}
            id={item.nome.toLowerCase()}
            onClick={() => setModelViwe(item.id)}>
            <h1>{item.nome}</h1>
            <div>
                <p>Valor: {Mask.moeda(item.venda)}</p>
                <p>Quantidade: {item.quantidade}</p>
                <p>Total: {Mask.moeda(item.venda * item.quantidade)}</p>
            </div>
            <button>acessar</button>
        </article>
    );
};

export default Produto;
