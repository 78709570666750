class Notify {
    constructor(segundos = 5) {
        this.segundos = segundos * 1000
        this.divNotify = document.createElement('div')
        this.divNotify.classList.add('notifyes')
        document.querySelector('body').appendChild(this.divNotify)
    }
    new(text) {
        let div = document.createElement('div')
        div.innerText = text
        div.classList.add('notify')

        this.divNotify.appendChild(div)
        setTimeout(() => {
            div.remove()
        }, this.segundos)
    }
}

export default new Notify(7)