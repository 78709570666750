import React from 'react'
import './styles.css'
const CardRalatorios = ({title,value}) => {
    console.log(title)
    return (
        <div id='CardRelatorios'>
            {title+': '  +value}
        </div>
    )
}

export default CardRalatorios
