import React from "react";

const Clientes = ({ clientes }) => {
    React.useEffect(handleEffect, [clientes]);
    function handleEffect() {}
    return null;
    // return (
    //     <section className="clientes">
    //         <h1 className="title">Clientes</h1>
    //         <div className="grid">
    //             <article>
    //                 <p> Clientes: {clientes.length} </p>
    //             </article>
    //             <article></article>
    //             <article></article>
    //         </div>
    //     </section>
    // );
};

export default Clientes;
