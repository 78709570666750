import React from "react";
import api from "../../../services/api";
import Loading from "../../../components/Loading";
import Notify from "../../../services/Notify";
import { DebitCreate } from "./DebtsCreate";
import { DebtsView } from "./DebtsView";
import Mask from "../../../services/Mask";
import Update from "./update";
import "./styles.css";

const ModalProdutos = ({ cliente, close, atualizer }) => {
    const { id } = cliente;
    let [infos, setInfos] = React.useState(cliente);
    let [atualizar, setAtualizar] = React.useState(false);
    let [dell, setDelete] = React.useState(false);
    let [enderecoViwe, setEnderecoViwe] = React.useState(false);
    let [loading, setLoading] = React.useState(false);
    let [error, setError] = React.useState(false);

    async function handleDelete() {
        setError(false);

        if (loading) return;
        if (!dell) return setDelete(true);

        setLoading(true);

        let { data, res } = await api.post("/clientes/delete", {
            token: localStorage.getItem("token"),
            id,
        });

        setLoading(false);

        if (data.error) {
            setError(data.error);
            return;
        } else if (res.status !== 200) {
            setError("Ocoreu algum erro");
            return;
        }
        Notify.new("Cliente deletado");
        await atualizer();
        close();
    }

    if (atualizar) {
        return (
            <Update
                infos={infos}
                close={setAtualizar}
                atualizer={atualizer}
                setInfos={setInfos}
            />
        );
    }
    if (enderecoViwe) {
        return (
            <div className="modalClienteAtualizar">
                <h1 className="title">Endereço</h1>
                <div className="infos">
                    <article>
                        <h1>
                            Rua:
                            <br />
                            {infos.adress.rua}
                        </h1>
                    </article>
                    <article>
                        <h1>
                            Numero:
                            <br />
                            {infos.adress.numero}
                        </h1>
                    </article>
                    <article>
                        <h1>
                            Bairro:
                            <br />
                            {infos.adress.bairro}
                        </h1>
                    </article>
                    {infos.adress.complemento && (
                        <article>
                            <h1>
                                Complemento:
                                <br />
                                {infos.adress.complemento}
                            </h1>
                        </article>
                    )}
                </div>
                <button onClick={() => setEnderecoViwe(!enderecoViwe)}>
                    ocultar
                </button>
            </div>
        );
    }
    return (
        <div className="modalCliente">
            <h1 className="title">{infos.nome}</h1>
            {infos.block ? (
                <span className="alert">
                    <h1> Cliente bloqueado</h1>
                </span>
            ) : (
                ""
            )}
            {loading && <Loading />}
            {error && <h1 className="error"> {error}</h1>}
            <div className="infos">
                {infos.email && (
                    <article>
                        <h1>
                            Email:
                            <br />
                            {infos.email}
                        </h1>
                    </article>
                )}
                <article>
                    <h1>
                        CPF:
                        <br />
                        {Mask.cpf(infos.cpf)}
                    </h1>
                </article>
                <article>
                    <h1>
                        Telefone:
                        <br />
                        {Mask.telefone(infos.telefone)}
                    </h1>
                </article>
                <article>
                    <h1>
                        Limite de vendas:
                        <br />
                        {infos.max_value}
                    </h1>
                </article>
            </div>
            <button onClick={() => setAtualizar(true)}>Atualizar</button>
            <button onClick={() => setEnderecoViwe(!enderecoViwe)}>Endereço</button>
            <button onClick={handleDelete}>{dell ? "Confirmar" : "Deletar"}</button>
            <DebitCreate id={infos.id} atualizer={atualizer} />
            <DebtsView debts={cliente.debts} id={infos.id} atualizer={atualizer} />
        </div>
    );
};

export default ModalProdutos;
