import React from "react";
import api from "./api";

export const GlobalContext = React.createContext();

export const GlobalStorage = ({ children }) => {
    let user = React.useState({});
    let vendas = React.useState([]);
    let [loadingVendas, setLoadingVendas] = React.useState(true);
    let produtos = React.useState([]);
    let [loadingProdutos, setLoadingProdutos] = React.useState(true);
    let clientes = React.useState([]);
    let [loadingClientes, setLoadingClientes] = React.useState(true);

    async function updateVendas() {
        let { data, res } = await api.post("vendas", {
            token: localStorage.getItem("token"),
        });
        if (data.error) return;
        if (res.status !== 200) return;
        vendas[1](data);
        setLoadingVendas(false);
    }
    async function updateProdutos() {
        let { data, res } = await api.post("produtos", {
            token: localStorage.getItem("token"),
        });
        if (data.error) return;
        if (res.status !== 200) return;
        produtos[1](data);
        setLoadingProdutos(false);
    }
    async function updateClientes() {
        let { data, res } = await api.post("clientes", {
            token: localStorage.getItem("token"),
        });
        if (data.error) return;
        if (res.status !== 200) return;
        clientes[1](data);
        setLoadingClientes(false);
    }
    function handleUpdate() {
        updateVendas();
        updateProdutos();
        updateClientes();
    }

    function handleClear() {
        vendas[1]([]);
        produtos[1]([]);
        clientes[1]([]);
        setLoadingProdutos(true);
        setLoadingClientes(true);
        setLoadingVendas(true);
    }

    React.useEffect(handleUpdate, []);

    return (
        <GlobalContext.Provider
            value={{
                vendas: {
                    value: vendas[0],
                    vendas: vendas[0],
                    set: vendas[1],
                    loading: loadingVendas,
                    update: updateVendas,
                },
                produtos: {
                    value: produtos[0],
                    produtos: produtos[0],
                    set: produtos[1],
                    loading: loadingProdutos,
                    update: updateProdutos,
                },
                clientes: {
                    value: clientes[0],
                    clientes: clientes[0],
                    set: clientes[1],
                    loading: loadingClientes,
                    update: updateClientes,
                },
                user,
                utils: {
                    handleUpdate,
                    handleClear,
                },
            }}>
            {children}
        </GlobalContext.Provider>
    );
};
