import React from "react";
import "./styles.css";

const Modal = ({ children, id = "Modal", close = () => {} }) => {
    function handleClose({ target }) {
        if (target.id === id) close();
    }

    return (
        <div id={id} className="modal" onClick={handleClose}>
            <div className="container">
                <button onClick={close} className="close rev"></button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
