import React from "react";
import "./styles.css";

let ordener = {
    nome: () => {
        console.log("nome");
    },
    data: (a, b) => {
        if (a.id === b.id) return 0;
        if (a.id > b.id) return 1;
        return -1;
    },
    datarev: (a, b) => {
        if (a.id === b.id) return 0;
        if (a.id < b.id) return 1;
        return -1;
    },
    quantidaderev: (a, b) => {
        if (a.quantidade === b.quantidade) return 0;
        if (a.quantidade < b.quantidade) return 1;
        return -1;
    },
    quantidade: (a, b) => {
        if (a.quantidade === b.quantidade) return 0;
        if (a.quantidade > b.quantidade) return 1;
        return -1;
    },
    total: (a, b) => {
        let aTotal = a.venda * a.quantidade;
        let bTotal = b.venda * b.quantidade;
        if (aTotal === bTotal) return 0;
        if (aTotal > bTotal) return 1;
        return -1;
    },
    totalrev: (a, b) => {
        let aTotal = a.venda * a.quantidade;
        let bTotal = b.venda * b.quantidade;
        if (aTotal === bTotal) return 0;
        if (aTotal < bTotal) return 1;
        return -1;
    },
    valorrev: (a, b) => {
        if (a.venda === b.venda) return 0;
        if (a.venda < b.venda) return 1;
        return -1;
    },
    valor: (a, b) => {
        if (a.venda === b.venda) return 0;
        if (a.venda > b.venda) return 1;
        return -1;
    },
};
const Filtros = ({ itens, create = () => {}, setFiltroBusca }) => {
    let [order, setOrder] = React.useState("data");
    let buscaRef = React.useRef();
    function busca() {
        console.log(buscaRef.current.value);

        if (buscaRef.current.value === "" || buscaRef.current.value === " ")
            return itens;

        let re = new RegExp(buscaRef.current.value, "i");
        let filter = (item) => re.test(item.nome);
        return itens.filter(filter);
    }
    function handleOrder(e) {
        setOrder(e.target.value);
    }

    function handleEffect() {
        let filtred = busca();
        console.log("filtred", filtred);
        let ordened = [];

        ordened = filtred.sort(ordener[order]);

        setFiltroBusca([...ordened]);
    }

    React.useEffect(handleEffect, [order, itens]);
    return (
        <div id="filtroComponent">
            <button className="rev" onClick={create}>
                Adicionar produtos
            </button>
            <select name="ordem" id="ordem" onChange={handleOrder}>
                <optgroup label="Crecente">
                    <option value="data">Data</option>
                    <option value="valor">Valor unitario</option>
                    <option value="total">Valor total</option>
                    <option value="quantidade">Quantidade</option>
                </optgroup>
                <optgroup label="Decrecente">
                    <option value="datarev">Data</option>
                    <option value="valorrev">Valor unitario</option>
                    <option value="totalrev">Valor total</option>
                    <option value="quantidaderev">Quantidade</option>
                </optgroup>
            </select>
            <div>
                <h3 htmlFor="busca">Buscar:</h3>

                <input
                    type="search"
                    id="busca"
                    ref={buscaRef}
                    onChange={handleEffect}
                    list="dl"
                    autoComplete="off"
                />
            </div>

            <datalist id="dl">
                {itens.map((item, i) => (
                    <option
                        key={`${item.nome}-${i}`}
                        value={item.nome.toLowerCase()}></option>
                ))}
            </datalist>
        </div>
    );
};
export default Filtros;
