import React from "react";
import api from "../../services/api";
import { Redirect } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Loading from "../../components/Loading";
import "./styles.css";
import notify from "../../services/Notify";
import Mask from "../../services/Mask";
// import SenhaRedefine from "./SenhaRedefine";
// import Adm from "./Adm";
// import News from "./News";
import { GlobalContext } from "../../services/globalContext";

export default () => {
    const [user, setUser] = React.useState();
    const [sair, setSair] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const { handleClear } = React.useContext(GlobalContext).utils;

    async function request() {
        setLoading(true);
        let { data } = await api.post("/users/perfil", {
            token: localStorage.getItem("token"),
        });
        setUser(data);
        setLoading(false);
    }

    React.useEffect(() => {
        request();
    }, []);

    function sai() {
        localStorage.removeItem("token");
        localStorage.removeItem("nome");
        handleClear();
        // document.location = "/login";
        setSair(true);
    }
    if (sair) {
        return <Redirect to="/login" />;
    } else if (loading) {
        return (
            <>
                <Header />
                <Loading />
            </>
        );
    }

    return (
        <>
            <Header />
            <div className="perfilPage">
                <section className="util">
                    <button className="rev" onClick={sai}>
                        sair
                    </button>
                    <button
                        className="rev"
                        onClick={() =>
                            notify.new(
                                `Teste de notificação\nMascaras\nValor:${Mask.moeda(
                                    10000.6,
                                )}\nData: ${Mask.dataPop()}\n
                               ${JSON.stringify(Mask.handleData(), null, 4)}
                                `,
                            )
                        }>
                        notify
                    </button>
                </section>
                <section>
                    <article>
                        <h2>Nome: {user.nome}</h2>
                    </article>
                    <article>
                        <h2>Email: {user.email}</h2>
                    </article>
                    <article>
                        <h2>Telefone: {Mask.telefone(user.telefone)}</h2>
                    </article>
                </section>
                {/* <News user={user} /> */}
                {/* <SenhaRedefine /> */}

                {/* {user.adm && <Adm user={user} />} */}
            </div>
            <Footer />
        </>
    );
};
