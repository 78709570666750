import React from "react";
import Input from "../../../../components/Input";
import Loading from "../../../../components/Loading";
import api from "../../../../services/api";
import { GlobalContext } from "../../../../services/globalContext";
import Notify from "../../../../services/Notify";
import "./styles.css";

export const DebitCreate = ({ id, atualizer }) => {
    let [form, setForm] = React.useState({});
    let [loading, setLoading] = React.useState(false);
    let [error, setError] = React.useState(false);
    let { value: produtos, update: updateProducts } = React.useContext(
        GlobalContext,
    ).produtos;
    let [estoque, setEstoque] = React.useState(0);
    let valorRef = React.useRef();

    async function handleCreate(e) {
        e.preventDefault();
        if (loading) return;
        setLoading(true);
        setError(false);

        let { data, res } = await api.post("/debts/create", {
            ...form,
            id,
            token: localStorage.getItem("token"),
        });

        setLoading(false);

        if (data.error) {
            setError(data.error);
            return;
        } else if (res.status !== 201) {
            setError("Ocoreu algum erro");
            return;
        }
        Notify.new("Débito salvo");
        setForm({ nome: "", quantidade: "" });
        valorRef.current.value = "";
        setEstoque(0);
        updateProducts();
        atualizer();
    }

    function handleInput({ target }) {
        if (target.id === "nome") {
            let produto = produtos.find((produto) => produto.nome === target.value);
            console.log(produto);
            if (produto !== undefined && produto.venda) {
                setForm({ ...form, nome: target.value, valor: produto.venda });
                setEstoque(produto.quantidade);
                return;
            }
        }
        setForm({ ...form, [target.id]: target.value });
    }

    return (
        <div id="debitCreate">
            <h1>Registrar débito</h1>
            {loading && <Loading />}
            {error && <h1 className="error">{error}</h1>}
            <form onSubmit={handleCreate}>
                <Input
                    placeholder="Nome"
                    label="Nome"
                    id="nome"
                    autoComplete="off"
                    value={form.nome}
                    onChange={handleInput}
                    list="produtos"
                    required
                />
                <div>
                    <label htmlFor="valor">Valor:</label>
                    <br />
                    <input
                        ref={valorRef}
                        label="Valor"
                        id="valor"
                        type="number"
                        step="0.01"
                        autoComplete="off"
                        value={form.valor}
                        onChange={handleInput}
                        min="0"
                        placeholder="Valor"
                        required
                    />
                </div>
                <Input
                    placeholder={estoque + " em estoque"}
                    label="Quantidade"
                    id="quantidade"
                    type="number"
                    autoComplete="off"
                    value={form.quantidade}
                    onChange={handleInput}
                    min="1"
                    required
                />
                <button type="submit">Registrar</button>
            </form>

            <datalist id="produtos">
                {produtos.length &&
                    produtos.map((produto) => (
                        <option key={produto.id} value={produto.nome}></option>
                    ))}
            </datalist>
        </div>
    );
};
