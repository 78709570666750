import React from "react";
import Input from "../../../components/Input";
import Loading from "../../../components/Loading";
import api from "../../../services/api";
import Notify from "../../../services/Notify";
import "./styles.css";

export const Update = ({ infos = {}, atualizer, close, setInfos }) => {
    let [error, setError] = React.useState(false);
    let [loading, setLoading] = React.useState(false);
    let [confirm, setConfirm] = React.useState(false);
    let [produto, setProduto] = React.useState(infos);

    function setValue({ target }) {
        setProduto({ ...produto, [target.id]: target.value });
    }

    async function handlerAtualizer() {
        if (!confirm) return setConfirm(true);
        if (loading) return;
        if (infos === produto) {
            return setError("Não foram feitas modificações");
        }
        setError(false);
        setLoading(true);

        let { data, res } = await api.post("produtos/update", {
            token: localStorage.getItem("token"),
            produto_id: produto.id,
            ...produto,
        });

        setLoading(false);

        if (data.error) {
            return setError(data.error);
        } else if (res.status !== 200) {
            return setError("Ouve algum erro");
        }
        await atualizer();
        setInfos(produto);
        close();
        Notify.new("Produto atualizado");
    }

    return (
        <div className="modalProdutoAtualizar">
            <h1 className="title">Atualizar</h1>
            {error ? <h1 className="error"> {error}</h1> : ""}

            {loading && <Loading />}
            <div className="infos">
                <article>
                    <Input
                        id="nome"
                        label="Nome:"
                        value={produto["nome"]}
                        onChange={setValue}
                        autocomplet="off"
                        required
                    />
                </article>
                <article>
                    <Input
                        id="compra"
                        label="Compra:"
                        value={produto["compra"]}
                        min="0"
                        type="number"
                        step="0.01"
                        onChange={setValue}
                        autocomplet="off"
                        required
                    />
                </article>
                <article>
                    <Input
                        id="venda"
                        label="Venda:"
                        value={produto["venda"]}
                        min="0"
                        type="number"
                        step="0.01"
                        onChange={setValue}
                        autocomplet="off"
                        required
                    />
                </article>
                <article>
                    <Input
                        id="quantidade"
                        label="Quantidade:"
                        type="number"
                        value={produto["quantidade"]}
                        onChange={setValue}
                        autocomplet="off"
                        required
                    />
                </article>
                <article>
                    <Input
                        id="codigo"
                        label="Codigo:"
                        value={produto["codigo"]}
                        onChange={setValue}
                        autocomplet="off"
                    />
                </article>
                <article>
                    <Input
                        id="description"
                        label="Description:"
                        value={produto["description"]}
                        onChange={setValue}
                        autocomplet="off"
                    />
                </article>
            </div>

            <button className="rev" onClick={handlerAtualizer}>
                {!confirm ? "atualizar" : "Confirmar!"}
            </button>
            <button
                className="rev"
                onClick={() => {
                    close(false);
                }}>
                Cancelar
            </button>
        </div>
    );
};
