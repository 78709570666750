import React from "react";
import api from "../../services/api";

import Input from "../../components/Input";
import Loading from "../../components/Loading";
import "./styles.css";
import { Redirect } from "react-router-dom";
import { GlobalContext } from "../../services/globalContext";

export default () => {
    let [email, setEmail] = React.useState("");
    let [senha, setSenha] = React.useState("");
    let [error, setError] = React.useState(false);
    let [loading, setLoading] = React.useState(false);
    let [logado, setLogado] = React.useState(false);
    let [register, setRegister] = React.useState(false);
    const { handleUpdate } = React.useContext(GlobalContext).utils;

    async function enviar(event) {
        event.preventDefault();
        setLoading(true);
        setError(false);

        let { data, res } = await api.post("/users/login", {
            email,
            senha: senha,
        });

        setLoading(false);

        if (data.error) {
            setError(data.error);
            return;
        } else if (res.status !== 200) {
            setError("Ouve algun erro");
            return;
        }

        localStorage.setItem("token", data.token);
        localStorage.setItem("nome", data.nome);
        handleUpdate();
        // document.location = "/";
        setLogado(true);
    }

    if (register) {
        return <Redirect to="/register" />;
    }
    if (logado) {
        return <Redirect to="/" />;
    }

    return (
        <div className="loginPage">
            <div className="container">
                <h1 className="title">Login</h1>
                <p className="error">{error}</p>
                {loading && <Loading />}

                <form onSubmit={enviar}>
                    <Input
                        type="email"
                        name="email"
                        id="email"
                        label="E-mail"
                        autoFocus
                        value={email}
                        onChange={({ target }) => setEmail(target.value)}
                        required
                    />
                    <br />
                    <h4 className="error" id="erroEmail">
                        {" "}
                    </h4>
                    <Input
                        type="password"
                        name="senha"
                        id="senha"
                        label="Senha"
                        value={senha}
                        onChange={({ target }) => setSenha(target.value)}
                        required
                    />
                    <br />
                    <h4 className="error" id="erroSenha">
                        {" "}
                    </h4>
                    <div className="botoes">
                        <button
                            className="rev"
                            type="button"
                            onClick={() => setRegister(true)}>
                            Registrar
                        </button>
                        <button className="rev" type="submit">
                            Login
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
