import React from "react";
import Loading from "../../../../components/Loading";
import api from "../../../../services/api";
import Mask from "../../../../services/Mask";
import Notify from "../../../../services/Notify";
import "./syles.css";

const totalReduce = (prev, current) => prev + current.valor * current.quantidade;
const unitReduce = (prev, current) => prev + current.quantidade;

const DebtsComponent = ({ debt, id, atualizer }) => {
    let section = React.useRef();
    let [loading, setLoading] = React.useState(false);
    let [error, setError] = React.useState(false);
    let [confirmPay, setConfirmPay] = React.useState(false);
    let [confirmDelete, setConfirmDelete] = React.useState(false);

    async function handleDelete() {
        if (loading) return;
        setConfirmPay(false);
        if (!confirmDelete) return setConfirmDelete(true);
        setError(false);
        setLoading(true);
        let { data, res } = await api.post("/debts/delete", {
            id: debt.id,
            cliente_id: id,
            token: localStorage.getItem("token"),
        });

        setLoading(false);

        if (data.error) {
            setError(data.error);
            return;
        } else if (res.status !== 200) {
            setError("Ocoreu algum erro");
            return;
        }
        Notify.new("Débito deletado");

        if (debt.produto_id !== null) {
            Notify.new(`${debt.quantidade} unidades adicionada(s) ao ${debt.nome}`);
        }

        atualizer();
    }

    async function handlePay() {
        if (loading) return;
        setConfirmDelete(false);
        if (!confirmPay) return setConfirmPay(true);
        setError(false);
        setLoading(true);
        let { data, res } = await api.post("/debts/pay", {
            id: debt.id,
            cliente_id: id,
            token: localStorage.getItem("token"),
        });

        setLoading(false);

        if (data.error) {
            setError(data.error);
            return;
        } else if (res.status !== 200) {
            setError("Ocoreu algum erro");
            return;
        }
        Notify.new("Débito pago");
        atualizer();
    }
    function handleClick(e) {
        if (loading) return;
        section.current.classList.toggle("open");
    }

    return (
        <section key={id} ref={section}>
            <article onClick={handleClick}>
                <h2>{debt.nome}</h2>
                <div className="infos">
                    <p>{Mask.data(debt.createdAt)}</p>
                    <p>Valor: {Mask.moeda(debt.valor)}</p>
                    <p>Quantidade: {debt.quantidade}</p>
                    <p>Total: {Mask.moeda(debt.valor * debt.quantidade)}</p>
                </div>
            </article>
            <div className="options">
                {loading && <Loading />}
                {error && <h1 className="error">{error}</h1>}
                <button className="delete" onClick={handleDelete}>
                    {!confirmDelete ? "Deletar" : "Confirmar!"}
                </button>
                <button className="update" onClick={handlePay}>
                    {!confirmPay ? "Pago" : "Confirmar!"}
                </button>
            </div>
        </section>
    );
};

export const DebtsView = ({ debts = [], id, atualizer }) => {
    if (!debts.length) {
        return (
            <div id="debtsView">
                <h1 className="title">Sem debitos</h1>
            </div>
        );
    }

    return (
        <div id="debtsView">
            <h1 className="title">Débitos</h1>
            <div className="resumo">
                <p>
                    Débitos: <b> {debts.length}</b>
                </p>
                <p>
                    Unidades: <b> {debts.reduce(unitReduce, 0)}</b>
                </p>
                <p>
                    Total: <b>{Mask.moeda(debts.reduce(totalReduce, 0))}</b>
                </p>
            </div>
            <div className="debts">
                {debts.map((debt) => (
                    <DebtsComponent
                        key={debt.id}
                        debt={debt}
                        id={id}
                        atualizer={atualizer}
                    />
                ))}
            </div>
        </div>
    );
};
