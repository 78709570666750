import React from "react";

const Venda = ({ venda = {} }) => {
    return (
        <div key={venda.id} className="venda">
            {venda.nome}
        </div>
    );
};

export default Venda;
