import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Loading from "../../components/Loading";
import "./styles.css";
import { Produtos } from "./Produtos";
import { Vendas } from "./Vendas";
import { GlobalContext } from "../../services/globalContext";
import Clientes from "./clientes";

export default () => {
    let {
        produtos: { value: produtos, loading: loadingProdutos },
        vendas: { value: vendas, loading: LoadingVendas },
        clientes: { value: clientes, loading: loadingClientes },
    } = React.useContext(GlobalContext);

    if (loadingProdutos || LoadingVendas || loadingProdutos || loadingClientes) {
        return (
            <>
                <Header />
                <Loading />
                <Footer />
            </>
        );
    }

    return (
        <>
            <Header />
            <div id="mainPage">
                <Produtos produtos={produtos} />
                <Vendas vendas={vendas} />
                <Clientes clientes={clientes} />
            </div>
            <Footer />
        </>
    );
};
