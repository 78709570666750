import React from "react";
import "./styles.css";
const Loading = () => {
    return (
        <div id="loading">
            <div className="spinner"></div>
            <h1>Carregando</h1>
        </div>
    );
};
export default Loading;
