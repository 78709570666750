import React from "react";

import { BrowserRouter, Switch, Route } from "react-router-dom";

import Main from "./pages/main";
import Login from "./pages/login/";
import Register from "./pages/register/register.jsx";
import Perfil from "./pages/perfil/perfil.jsx";

import Clientes from "./pages/clientes/index.jsx";

import Produtos from "./pages/produtos";

import Vendas from "./pages/vendas";
import newVendas from "./pages/newVendas";
import RelatoriosPage from './pages/relatorios'
const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Main} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />

            <Route exact path="/clientes" component={Clientes} />

            <Route exact path="/produtos" component={Produtos} />

            <Route exact path="/vendas" component={Vendas} />
            <Route exact path="/relatorios" component={RelatoriosPage} />
            <Route exact path="/nvendas" component={newVendas} />

            <Route path="/perfil" component={Perfil} />

            <Route path="*" component={Main} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
