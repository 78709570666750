import React from 'react'
import './styles.css'
const SectionRealatorios = ({ title, children }) => {
    return (
        <>
            <div>
                <span>{title}</span>
                <section>{children}</section>
            </div>
        </>
    )
}

export default SectionRealatorios
