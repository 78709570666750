import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "./vendas.css";
import Create from "./create";
import Acordeon from "./acordeon";
import Mask from "../../services/Mask";
import { GlobalContext } from "../../services/globalContext";
import Loading from "../../components/Loading";

const reduceTotal = (prev, current) => {
    return prev + current.valor * current.quantidade;
};
export default () => {
    let { value: vendas, loading } = React.useContext(GlobalContext).vendas;
    let order = React.useMemo(handleOrder, [vendas]);
    function handleOrder() {
        let obj = {};
        vendas.forEach((venda) => {
            let data = Mask.dataPop(venda.createdAt);
            if (obj[data] && obj[data].length) {
                obj[data].push(venda);
            } else {
                obj[data] = [venda];
            }
        });
        return obj;
    }
    // React.useEffect(handleOrder, [vendas]);
    if (loading || !Object.keys(order)) {
        return (
            <div id="venda">
                <Header />
                <Create />
                <Loading />
            </div>
        );
    }

    if (!vendas.length) {
        return (
            <div id="venda">
                <Header />
                <Create />
                <h1 className="title">Você não possue vendas cadastradas</h1>
            </div>
        );
    }

    return (
        <div id="venda">
            <Header />
            <Create />

            <div id="vendas">
                {Object.keys(order).map((date) => {
                    let total = order[date].reduce(reduceTotal, 0);
                    return (
                        <div key={date}>
                            <div className="date">
                                <h1>
                                    {date} | {Mask.moeda(total)}
                                </h1>
                            </div>
                            {order[date].map((item) => {
                                return (
                                    <Acordeon key={item.id} id={item.id}>
                                        <h1>{item.nome}</h1>
                                        <p>
                                            Valor: <br />
                                            {Mask.moeda(item.valor)}
                                        </p>
                                        <p>
                                            Quantidade: <br /> {item.quantidade}
                                        </p>
                                        <p className="data">
                                            {Mask.data(item.createdAt)}
                                        </p>
                                        <p className="total">
                                            Total: <br />
                                            {Mask.moeda(
                                                item.quantidade * item.valor,
                                            )}
                                        </p>
                                    </Acordeon>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <Footer />
        </div>
    );
};
