import React from "react";
import "./styles.css";
import api from "../../../services/api";
import Loading from "../../../components/Loading";
import notify from "../../../services/Notify";
import Input from "../../../components/Input";

const Create = ({ setAtualizer }) => {
    let [nome, setNome] = React.useState("");
    let [valor, setValor] = React.useState("");
    let [quantidade, setQuantidade] = React.useState("");
    let [erro, setErro] = React.useState(false);
    let [loading, setLoading] = React.useState(false);
    let [produtos, setProdutos] = React.useState(false);
    let [estoque, setEstoque] = React.useState(0);
    let nomeElement = React.useRef();

    function request() {
        api.post("/produtos", {
            token: localStorage.getItem("token"),
        })
            .then(({ data }) => {
                if (data.error) return setErro(data.error);
                data.reverse();
                setProdutos(data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setErro("Ouve algum erro");
            });
    }
    React.useEffect(() => {
        request();
    }, []);

    async function sendInfos(event) {
        event.preventDefault();

        setErro(false);
        if (!nome) {
            return setErro("O nome deve ser fonecido");
        } else if (!valor) {
            return setErro("O valor deve ser fonecido");
        } else if (!quantidade) {
            return setErro("A quantidade deve ser fonecida");
        }

        setLoading(true);
        let { data, res } = await api.post("/vendas/create", {
            nome,
            valor,
            quantidade,
            token: localStorage.getItem("token"),
        });

        setLoading(false);
        if (data.error) {
            setErro(data.error);
            return;
        } else if (res.status !== 201) {
            setErro("Ocoreu algum erro");
            return;
        }
        nomeElement.current.focus();
        notify.new("Venda registrada");
        if (quantidade >= estoque)
            notify.new(`"${nome}" tem ${quantidade - estoque} em estoque`);
        setNome("");
        setValor("");
        setQuantidade("");
        setEstoque(0);
        setAtualizer(true);
        request();
    }

    return (
        <div className="header" onSubmit={sendInfos}>
            <h2>Registrar venda</h2>
            {erro !== false ? <h2 className="error">{erro}</h2> : ""}
            {loading ? <Loading /> : ""}
            <form>
                <div>
                    <div>
                        <label htmlFor="nome">Nome</label>
                        <br />
                        <input
                            ref={nomeElement}
                            type="text"
                            id="nome"
                            label="Nome"
                            list="nomes"
                            value={nome}
                            onChange={({ target }) => {
                                setNome(target.value);

                                let val = produtos.find(
                                    (produto) => produto.nome === target.value,
                                );
                                if (val) {
                                    setValor(val.venda);
                                    setEstoque(val.quantidade);
                                } else {
                                    setValor("");
                                    setEstoque(0);
                                }
                            }}
                            autoComplete="off"
                        />
                    </div>
                </div>
                <div>
                    <Input
                        label="Valor"
                        type="number"
                        id="valor"
                        autoCapitalize="on"
                        min="0.01"
                        step="0.01"
                        value={valor}
                        onChange={({ target }) => {
                            setValor(target.value);
                        }}
                        autoComplete="off"
                    />
                </div>
                <div id="vendaquantidade">
                    <Input
                        label="Quantidade"
                        type="number"
                        id="quantidade"
                        placeholder={`${estoque} em estoque`}
                        value={quantidade}
                        onChange={({ target }) => setQuantidade(target.value)}
                        min="1"
                        autoComplete="off"
                    />
                </div>
                <button id="rev">Registrar</button>
            </form>

            {produtos && (
                <datalist id="nomes">
                    {produtos.map((produto, i) => (
                        <option key={produto.nome + i} value={produto.nome}></option>
                    ))}
                </datalist>
            )}
        </div>
    );
};
export default Create;
