import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import Floating from "../../components/Floating";
import Modal from "../../components/Modal";
import ModalProdutos from "./find/find";
import Filtros from "./filtros/index.jsx";
import CreateProdutos from "./create/criar";
import "./styles.css";
import { GlobalContext } from "../../services/globalContext";
import Produto from "./produto";

export default () => {
    let { value: produtos, update: updateProdutos, loading } = React.useContext(
        GlobalContext,
    ).produtos;
    let [modalViwe, setModelViwe] = React.useState(false);
    let [modalCreate, setModalCreate] = React.useState(false);
    let [filtroBusca, setFiltroBusca] = React.useState([...produtos]);

    if (loading) {
        return (
            <>
                <Header />
                <Loading />
                <Footer />
            </>
        );
    } else if (!produtos.length) {
        return (
            <>
                <Header />

                <div id="Produtos">
                    <Floating>
                        <button className="rev" onClick={() => setModalCreate(true)}>
                            Adicionar produtos
                        </button>
                    </Floating>
                    {modalCreate && (
                        <Modal close={() => setModalCreate(false)}>
                            <CreateProdutos
                                atualizar={updateProdutos}
                                close={() => setModalCreate(false)}
                            />
                        </Modal>
                    )}
                    <h1 className="title">Você não possue produtos no estoque</h1>
                    <div className="title">
                        <button className="rev" onClick={() => setModalCreate(true)}>
                            Adicionar produtos
                        </button>

                        <Footer />
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <Header />
            <div id="Produtos">
                <Filtros
                    itens={produtos}
                    create={() => setModalCreate(true)}
                    setFiltroBusca={setFiltroBusca}
                />
                <Floating>
                    <button className="rev" onClick={() => setModalCreate(true)}>
                        Adicionar produtos
                    </button>
                </Floating>

                {modalViwe && (
                    <Modal close={() => setModelViwe(false)}>
                        <ModalProdutos
                            produto={produtos.find(
                                (produto) => produto.id === modalViwe,
                            )}
                            atualizer={updateProdutos}
                            close={() => setModelViwe(false)}
                        />
                    </Modal>
                )}
                {modalCreate && (
                    <Modal close={() => setModalCreate(false)}>
                        <CreateProdutos
                            close={() => setModalCreate(false)}
                            atualizar={updateProdutos}
                        />
                    </Modal>
                )}
                {loading && <Loading />}
                <div className="itens">
                    {filtroBusca.map((item) => (
                        <Produto
                            key={item.id}
                            item={item}
                            setModelViwe={setModelViwe}
                        />
                    ))}

                    <article id="criar">
                        <div onClick={() => setModalCreate(true)}>
                            <h1>+</h1>
                        </div>
                    </article>
                </div>
                {/* <datalist id='dl'>
                    {itens.map((item, i) => (
                        <option key={`${item.nome}-${i}`} value={item.nome}>{item.nome}</option>
                    ))}
                </datalist> */}
            </div>
            <Footer />
        </>
    );
};
