import React from "react";
import Input from "../../../components/Input";
import "./styles.css";

const Filtros = ({ itens = [], create = () => {} }) => {
  function busca(e) {
    e.preventDefault();
    let element = document.getElementById(e.target.value);
    if (!element) return;
    window.scroll({
      top: element.offsetTop - 3,
      behavior: "smooth",
    });
  }

  return (
    <div id="filtroComponent">
      <button className="rev" onClick={create}>
        Cadastrar Clientes
      </button>

      <div>
        <Input
          type="search"
          label="Buscar:"
          onChange={busca}
          list="dl"
          autoComplete="off"
        />
      </div>
      <datalist id="dl">
        {itens.map((item, i) => (
          <option
            key={`${item.nome}-${i}`}
            value={item.nome.toLowerCase()}
          ></option>
        ))}
      </datalist>
    </div>
  );
};
export default Filtros;
