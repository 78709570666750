import React from "react";
import api from "../../services/api";
import { Redirect } from "react-router-dom";
import Input from "../../components/Input";
import Mask from "../../services/Mask";

let formCampos = [
  {
    label: "Nome",
    type: "text",
    id: "nome",
  },
  {
    label: "Telefone",
    type: "text",
    id: "telefone",
    option: {
      maxLength: 17,
      minLength: 17,
    },
  },
  {
    label: "CPF",
    type: "text",
    id: "cpf",
    option: {
      maxLength: 14,
      minLength: 14,
    },
  },
  {
    label: "Email",
    type: "email",
    id: "email",
    option: {
      minLength: 1,
    },
  },
  {
    label: "Senha",
    type: "password",
    id: "senha",
    option: {
      minLength: 8,
    },
  },
  {
    label: "Repita a senha",
    type: "password",
    id: "rep",
    option: {
      minLength: 8,
    },
  },
];

let campos = formCampos.reduce((acc, campo) => {
  return { ...acc, [campo.id]: "" };
}, {});

export default () => {
  let [form, setForm] = React.useState(campos),
    [error, setError] = React.useState(false),
    [loading, setLoading] = React.useState(false),
    [login, setLogin] = React.useState(false);

  async function enviar(event) {
    event.preventDefault();
    if (form.senha !== form.rep) {
      return setError("A senha deve ser verificada");
    }
    setLoading(true);
    setError(false);
    let { data, res } = await api.post("/users/register", {
      ...form,
    });
    setLoading(false);
    if (data.error) {
      setError(data.error);
      return;
    } else if (res.status !== 201) {
      setError("Ouve algun erro");
      return;
    }

    setLogin(true);
  }

  function change({ target }) {
    let value = target.value,
      id = target.id;

    if (id === "telefone") {
      value = Mask.telefone(value);
    } else if (id === "cpf") {
      value = Mask.cpf(value);
    }
    setForm({ ...form, [id]: value });
  }

  if (login) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="registerPage">
      <div className="container">
        <h1 className="title">Registrar</h1>
        <p className="error">{error}</p>
        <p className="error">
          {loading && "Estabalecendo conexão com o servidor"}
        </p>

        <form onSubmit={enviar}>
          {formCampos.map((campo) => (
            <div key={campo.id}>
              <Input
                label={campo.label}
                type={campo.type}
                id={campo.id}
                value={form[campo.id]}
                onChange={change}
                {...campo.option}
                required
              />
              <br />
            </div>
          ))}
          <button className="rev" type="button" onClick={() => setLogin(true)}>
            Login
          </button>
          <button className="rev" type="submit">
            Registrar
          </button>
        </form>
      </div>
    </div>
  );
};
