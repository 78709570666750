import React from "react";
import Input from "../../../components/Input";
import Loading from "../../../components/Loading";
import api from "../../../services/api";
import notify from "../../../services/Notify";
import "./styles.css";
let formCampos = [
    {
        label: "description",
        type: "text",
        id: "nome",
        config: {
            required: true,
            autoFocus: true,
            minLength: 3,
        },
    },
    {
        label: "Valor de compra",
        type: "number",
        id: "compra",
        config: {
            min: 0,
            required: true,
            step: "0.01",
        },
    },
    {
        label: "Valor de venda",
        type: "number",
        id: "venda",
        config: {
            required: true,
            min: 0,
            step: "0.01",
        },
    },
    {
        label: "Quantidade",
        type: "number",
        id: "quantidade",
        config: {
            required: true,
        },
    },
    {
        label: "codigo",
        type: "text",
        id: "codigo",
        config: {},
    },

    {
        label: "Descrição",
        type: "text",
        id: "description",
        config: {
            required: true,
        },
    },
];

let campos = formCampos.reduce((acc, campo) => {
    return {
        ...acc,
        [campo.id]: campo.default !== undefined ? campo.default : "",
    };
}, {});

const CreateProdutos = ({ atualizar, close }) => {
    const [produto, setProduto] = React.useState(campos);
    const [erro, setErro] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [porcentagem, setPorcentagem] = React.useState(
        localStorage.getItem("porcentagem") || 60,
    );

    async function sendInfos(event) {
        event.preventDefault();
        if (loading) return;
        setLoading(true);
        setErro(false);
        let { data, res } = await api.post("/produtos/create", {
            ...produto,
            token: localStorage.getItem("token"),
        });
        setLoading(false);
        if (data.error) {
            setErro(data.error);
            return;
        } else if (res.status !== 201) {
            setErro("Ocoreu algum erro");
            return;
        } else {
            // alert('Produto salvo')
        }
        notify.new("Produto salvo");
        await atualizar();
        await busca(produto.nome.toLowerCase());
        // close()
        setProduto(campos);
    }
    function busca(name) {
        let element = document.getElementById(name);
        if (!element) return;
        window.scroll({
            top: element.offsetTop - 3,
            behavior: "smooth",
        });
    }

    function setValue({ target }) {
        if (target.id === "compra") return sugect(target.value);
        setProduto({ ...produto, [target.id]: target.value });
    }

    function sugect(compra = 0) {
        let preco = parseFloat(compra);

        let adicao = parseFloat(preco * (porcentagem / 100));
        setProduto({ ...produto, compra, venda: (preco + adicao).toFixed(2) });
    }

    return (
        <>
            <div className="produtoCreate">
                <h1 className="title">Registrar produto</h1>
                {erro !== false ? <h1 className="error">{erro}</h1> : ""}
                {loading && <Loading />}
                <form onSubmit={sendInfos}>
                    <section>
                        <article key="nome">
                            <Input
                                id="nome"
                                autoComplete="off"
                                label="Nome"
                                type="text"
                                required
                                autoFocus
                                minLength="3"
                                value={produto["nome"]}
                                onChange={setValue}
                            />
                        </article>
                        <article key="compra" id="porcentagem">
                            <div>
                                <Input
                                    id="compra"
                                    label="Valor de compra"
                                    autoComplete="off"
                                    type="number"
                                    min="0"
                                    required
                                    step="0.01"
                                    value={produto["compra"]}
                                    onChange={setValue}
                                />
                            </div>
                            <div>
                                <Input
                                    id="porcentagem"
                                    label="Porcentagem (opcional)"
                                    min="0"
                                    type="number"
                                    step="0.01"
                                    value={porcentagem}
                                    onChange={({ target }) => {
                                        setPorcentagem(target.value);
                                        localStorage.setItem(
                                            "porcentagem",
                                            target.value,
                                        );
                                        sugect(produto.compra);
                                    }}
                                />
                            </div>
                        </article>
                        <article key="venda">
                            <Input
                                id="venda"
                                label="Valor de venda"
                                autoComplete="off"
                                type="number"
                                min="0"
                                required
                                step="0.01"
                                value={produto["venda"]}
                                onChange={setValue}
                            />
                        </article>
                        <article key="quantidade">
                            <Input
                                id="quantidade"
                                label="Quantidade"
                                type="number"
                                required
                                min="1"
                                value={produto["quantidade"]}
                                onChange={setValue}
                            />
                        </article>
                        <article key="codigo">
                            <Input
                                id="codigo"
                                label="Código (opcional)"
                                type="text"
                                value={produto["codigo"]}
                                onChange={setValue}
                            />
                        </article>
                        <article key="description">
                            <label htmlFor="description">Descrição (opcional)</label>
                            <br />
                            <textarea
                                id="description"
                                minLength="3"
                                step="0.01"
                                value={produto["description"]}
                                onChange={setValue}
                            />
                        </article>
                        <article className="botoes">
                            <button className="rev" type="submit">
                                Registrar
                            </button>
                        </article>
                    </section>
                </form>
            </div>
        </>
    );
};
export default CreateProdutos;
