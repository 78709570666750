import React from "react";
import api from "../../../services/api";
import { useState } from "react";
import Loading from "../../../components/Loading";
import notify from "../../../services/Notify";
import "./styles.css";
import { GlobalContext } from "../../../services/globalContext";

const Acordeon = ({ children, id }) => {
    let {
        vendas: { update: updateVendas },
        produtos: { update: updateProdutos },
    } = React.useContext(GlobalContext);
    let [loading, setLoading] = useState(false);
    let [error, setError] = useState(false);
    let [dell, setDelete] = useState(false);

    async function deletar() {
        if (!dell) return setDelete(true);
        if (loading) return;
        setLoading(true);
        let { data, res } = await api.post("/vendas/delete", {
            token: localStorage.getItem("token"),
            id,
        });
        setLoading(false);

        if (data.error) {
            return setError(data.error);
        } else if (res.status !== 200) {
            return setError("status " + res.status);
        }
        notify.new("Venda deletada");
        updateVendas();
        updateProdutos();
    }
    return (
        <div className="acordeonVendas">
            <input type="checkbox" id={id} />
            <label className="tab-label" htmlFor={id}>
                {children}
            </label>
            <div className="tab-content">
                <div>
                    {loading && <Loading />}
                    {error !== false && <h1>{error}</h1>}
                </div>
                <button id={"dell-" + id} onClick={deletar} className="rev del">
                    {!dell ? "Deletar" : "Confirmar!"}
                </button>
            </div>
        </div>
    );
};

export default Acordeon;
