import React from "react";
import login from "./services/login";

import "./styles/styles.css";
import "./styles/notifyes.css";
import "./styles/animations.css";

import Routes from "./routes";
import { GlobalStorage } from "./services/globalContext";

const App = () => {
    login().then((data) => {
        if (
            document.location.pathname === "/login" ||
            document.location.pathname === "/register"
        ) {
        } else {
            if (!data) {
                localStorage.removeItem("nome");
                localStorage.removeItem("email");
                localStorage.removeItem("senha");
                return (document.location = "/login");
            }
        }
    });

    return (
        <GlobalStorage>
            <Routes />
        </GlobalStorage>
    );
};

export default App;
