// import React from 'react'
import "./styles.css";

const Footer = () => null;
//  () => (
//     <footer className='footer' id='main-footer'>
//        <a href='https://github.com/vbugs130' target='blank' ><button >vbugs130</button></a>
//     </footer>
// )

export default Footer;
